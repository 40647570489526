import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Modal } from '@mui/material';

import { Images } from '@/constants/Images';
import numberFormatter from '@/formatters/ShortNumberConverter';
import { PlaylistRecommendationsDataModel } from '@/models/Playlist';

const PlaylistModal = ({
  open,
  onClose,
  playlist,
}: {
  open: boolean;
  onClose: () => void;
  playlist: PlaylistRecommendationsDataModel;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <>
          <div className="to-do-modal">
            <div className={`playlist-recommendation-card`}>
              <div className="d-flex gap16">
                <div>
                  <object className="playlist-image" data={playlist?.image} type="image/png">
                    <img className="playlist-image" src={Images.playlistIcon} alt="Fallback playlist img" />
                  </object>
                </div>
                <div className="text-left mt-auto mb-auto">
                  <p>{playlist?.name}</p>
                  <p className="text-faded small capitalize">
                    {playlist?.genres.map((item, index) => `${item}${index < playlist?.genres.length - 1 ? ', ' : ''}`)}{' '}
                    - by {playlist?.curatorDetails.name}
                  </p>
                </div>
              </div>
              <div className="d-flex mt20 text-center">
                <div className="flex-grow">
                  <p>{numberFormatter(playlist?.stats.followers)}</p>
                  <p className="small text-faded">{t('INSIGHTS-PAGE.COMMON.FOLLOWERS')}</p>
                </div>
                <div className="flex-grow w30p">
                  <p>{playlist?.stats.averageTrackPopularity}</p>
                  <p className="small text-faded">{t('PLAYLISTING.AVG-SONG-POPULARITY')}</p>
                </div>
                <div className="flex-grow">
                  <p>{playlist?.stats.trackCount}</p>
                  <p className="small text-faded">{t('PLAYLISTING.SONGS')}</p>
                </div>
              </div>
              <div className="mt20 text-left mb20">
                <p className="small">{t('PLAYLISTING.DESCRIPTION')}</p>
                <p className="small text-faded capitalize">{playlist?.description}</p>
              </div>
              {playlist?.similarArtists.length > 0 && (
                <div className="mt20 text-left">
                  <p className="small">{t('PLAYLISTING.SIMILAR-ARTISTS')}</p>
                  <div className="d-flex mt8">
                    <div className="d-flex">
                      {playlist.similarArtists.map((item, index) => (
                        <img
                          key={item.name}
                          className={`similar-artist ${index > 0 ? 'ml-8' : ''}`}
                          src={item.image}
                          alt=""
                        />
                      ))}
                    </div>
                    <div className="pl8 mt-auto mb-auto">
                      <p className="text-faded small pt0">
                        Featuring{' '}
                        {playlist.similarArtists.map(
                          (item, index: number) =>
                            `${item.name}${index < playlist?.similarArtists.length - 1 ? ', ' : ''}`
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <div className="footer-button modal">
                <Button
                  className="btn-black-2 w100p ml0"
                  onClick={() => {
                    window.open(playlist.url);
                  }}
                >
                  {t('PLAYLISTING.LISTEN-ON-SPOTIFY')}
                </Button>
              </div>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};

export default PlaylistModal;
