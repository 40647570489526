import React from 'react';
import { useTranslation } from 'react-i18next';

import { format } from 'date-fns';

import { Images } from '@/constants/Images';
import numberFormatter from '@/formatters/ShortNumberConverter';
import { SpotifyChosenPlaylistsModel, SpotifyChosenTracksModel } from '@/models/Spotify';

const PitchSummary = ({
  tracks,
  playlists,
}: {
  tracks: SpotifyChosenTracksModel[];
  playlists: SpotifyChosenPlaylistsModel[];
}) => {
  const { t } = useTranslation();

  return (
    <>
      <h3 data-testid="playlisting-summary">{t('PLAYLISTING.SUMMARY')}</h3>
      <p className="text-faded mb48 mb20-lg-down mt16">{t('PLAYLISTING.SUMMARY-DESCRIPTION')}</p>
      <div className="card mb20 d-flex gap20 flex-wrap">
        <div className="flex-w50p-21 w100p-lg-down text-left">
          <h3>Your selected tracks</h3>

          {tracks?.map((track, index: number) => (
            <div
              key={index}
              style={
                {
                  cursor: 'default',
                  '--animation-number': `${index}`,
                } as React.CSSProperties
              }
              className={`list-item list-item-parent campaign card-inner d-flex mt20`}
            >
              <img src={track.images[0].url} alt="" />
              <div className="mt-auto mb-auto">
                <p>{track.name}</p>
                <p className="text-faded small">
                  {track.artists?.map(
                    (item, index: number) => `${item.name}${index < track.artists?.length - 1 ? ', ' : ''}`
                  )}
                </p>
                <p className="text-faded small">Released on: {format(new Date(track?.release_date), 'yyyy-MM-dd')}</p>
              </div>
            </div>
          ))}
          <div className="hide-lg-up">
            <div className="d-flex form-divider mt10">
              <div className="line"></div>
            </div>
          </div>
        </div>
        <div className="vertical-divider hide-lg-down"></div>

        <div className="flex-w50p-21 w100p-lg-down text-left">
          <h3>Your selected playlists</h3>

          <div className="mt20">
            {playlists?.map((playlist, index: number) => (
              <div
                key={playlist.spotifyPlaylistId}
                style={
                  {
                    cursor: 'default',
                    '--animation-number': `${index}`,
                  } as React.CSSProperties
                }
                className={`list-item list-item-parent campaign card-inner d-flex mt20`}
              >
                <div>
                  {playlist.image ? (
                    <object data={playlist.image} type="image/png;image/svg">
                      <img src={Images.playlistIcon} alt="Fallback playlist img" />
                    </object>
                  ) : (
                    <img className="playlist-image new" src={Images.playlistIcon} alt="Fallback playlist img" />
                  )}
                </div>
                <div>
                  <p className="text-left">{playlist.name}</p>
                  <p className="text-left small text-faded">Curator: {playlist.curatorName}</p>
                  <p className="text-left small text-faded">Followers: {numberFormatter(playlist.followers)}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default PitchSummary;
