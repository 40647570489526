import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Autocomplete, Chip, Icon } from '@mui/material';
import { format } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

import Loading from '@/components/utility/Loading';
import AutoCompleteInput from '@/components/utility/microcomponents/AutoCompleteInput';
import ButtonComponent from '@/components/utility/microcomponents/Button';
import useArtist from '@/hooks/artist/useArtist';
import useSpotifyArtistAlbums from '@/hooks/playlist/useSpotifyArtistAlbums';
import useSpotifyArtistSingles from '@/hooks/playlist/useSpotifyArtistSingles';
import useSpotifySearchArtistTracks from '@/hooks/playlist/useSpotifySearchArtistTracks';
import { PlaylistPitchModel } from '@/models/Playlist';
import { SpotifyChosenTracksModel } from '@/models/Spotify';

import AlbumTracksModal from './AlbumTracksModal';

const PitchChooseSongs = ({
  outputTracks,
  canStep,
}: {
  outputTracks: (tracks: SpotifyChosenTracksModel[]) => void;
  canStep: (value: boolean) => void;
}) => {
  const { t } = useTranslation();

  const { artist } = useArtist();

  const [trackSearchValue, setTrackSearchValue] = useState<string>('');
  const [autoCompleteKey, setAutoCompleteKey] = useState<string>(uuidv4());

  const [chosenTracks, setChosenTracks] = useState<SpotifyChosenTracksModel[]>([]);
  const [activeAlbumId, setActiveAlbumId] = useState<string>();
  const [showAlbumModal, setShowAlbumModal] = useState<boolean>(false);

  const { setValue, watch } = useFormContext<PlaylistPitchModel>();
  const formPlaylistPitches = watch('playlistPitches');

  const { artistSingles, artistSinglesIsLoading } = useSpotifyArtistSingles({
    spotifyId: artist?.platformIds.spotifyId,
  });
  const { artistAlbums, artistAlbumsIsLoading } = useSpotifyArtistAlbums({
    spotifyId: artist?.platformIds.spotifyId,
  });
  const { searchArtistTracksOptions, searchArtistTracksOptionsIsLoading, refetchSearchArtistTracksOptions } =
    useSpotifySearchArtistTracks({
      artistName: artist?.details.name,
      search: trackSearchValue,
    });

  const activeAlbum = useMemo(() => {
    if (!artistAlbums) return;

    return artistAlbums.find((item) => item.id === activeAlbumId);
  }, [activeAlbumId, artistAlbums]);

  useEffect(() => {
    outputTracks(chosenTracks);
  }, [chosenTracks, outputTracks]);

  useEffect(() => {
    if (chosenTracks.length) return;

    const playlistPitchTrackIds = formPlaylistPitches.map((pitch) => pitch.spotifyTrackId);
    let formChosenTracks: SpotifyChosenTracksModel[] = [];

    for (const trackId of playlistPitchTrackIds) {
      const singleTrack = artistSingles?.find((single) => single.tracks.find((track) => track.id === trackId));
      const albumTrack = artistAlbums?.find((album) => album.tracks.find((track) => track.id === trackId));

      if (singleTrack) {
        const track = singleTrack.tracks.find((t) => t.id === trackId);
        if (!track) continue;

        formChosenTracks = [
          ...formChosenTracks,
          {
            id: track.id,
            name: track.name,
            type: 'single',
            artists: track.artists,
            images: singleTrack.images,
            release_date: singleTrack.release_date,
          },
        ];
      } else if (albumTrack) {
        const track = albumTrack.tracks.find((t) => t.id === trackId);
        if (!track) continue;

        formChosenTracks = [
          ...formChosenTracks,
          {
            id: track.id,
            name: track.name,
            type: 'album',
            artists: track.artists,
            images: albumTrack.images,
            release_date: albumTrack.release_date,
          },
        ];
      }
    }

    setChosenTracks(formChosenTracks);
  }, [formPlaylistPitches, artistSingles, artistAlbums, chosenTracks.length]);

  useEffect(() => {
    if (trackSearchValue.length) {
      refetchSearchArtistTracksOptions();
    }
  }, [refetchSearchArtistTracksOptions, trackSearchValue]);

  const handleResetTracksAutocomplete = useCallback(async () => {
    setTrackSearchValue('');
    setAutoCompleteKey(uuidv4());
    await refetchSearchArtistTracksOptions(undefined);
  }, [refetchSearchArtistTracksOptions]);

  const handleUpdateTrack = useCallback(
    async (value: SpotifyChosenTracksModel) => {
      if (chosenTracks.map((item) => item.id).includes(value.id)) {
        setValue(
          'playlistPitches',
          formPlaylistPitches.filter((pitch) => pitch.spotifyTrackId !== value.id)
        );
        setChosenTracks(chosenTracks.filter((item) => item.id !== value.id));
      } else {
        setValue('playlistPitches', [...formPlaylistPitches, { spotifyTrackId: value.id }]);
        setChosenTracks([...chosenTracks, value]);
      }

      await handleResetTracksAutocomplete();
    },
    [chosenTracks, formPlaylistPitches, handleResetTracksAutocomplete, setValue]
  );
  const handleUpdateAlbumTrack = useCallback(
    async (albumTracks: SpotifyChosenTracksModel[], trackIdsToRemove: string[]) => {
      let newPlaylistPitches = formPlaylistPitches;
      let newChosenTracks = chosenTracks;

      for (const trackId of trackIdsToRemove) {
        newPlaylistPitches = newPlaylistPitches.filter((pitch) => pitch.spotifyTrackId !== trackId);
        newChosenTracks = newChosenTracks.filter((item) => item.id !== trackId);
      }

      for (const track of albumTracks) {
        if (newPlaylistPitches.some((pitch) => pitch.spotifyTrackId === track.id)) continue;

        newPlaylistPitches = [...newPlaylistPitches, { spotifyTrackId: track.id }];
        newChosenTracks = [...newChosenTracks, track];
      }

      setValue('playlistPitches', newPlaylistPitches);
      setChosenTracks(newChosenTracks);
    },
    [chosenTracks, formPlaylistPitches, setValue, setChosenTracks]
  );

  const handleDeleteTrack = useCallback(
    (trackId: string) => {
      setValue(
        'playlistPitches',
        formPlaylistPitches.filter((item) => item.spotifyTrackId !== trackId)
      );
      setChosenTracks(chosenTracks.filter((item) => item.id !== trackId));
    },
    [chosenTracks, formPlaylistPitches, setValue]
  );

  useEffect(() => {
    canStep(!!(formPlaylistPitches.length > 0));
  }, [formPlaylistPitches, canStep]);

  return (
    <>
      {activeAlbum && (
        <AlbumTracksModal
          open={showAlbumModal}
          onClose={() => {
            setActiveAlbumId(undefined);
            setShowAlbumModal(false);
          }}
          album={activeAlbum}
          chosenTracks={chosenTracks}
          handleUpdateAlbumTrack={handleUpdateAlbumTrack}
        />
      )}
      <h3 data-testid="choose-songs-title">{t('PLAYLISTING.CHOOSE-YOUR-SONGS')}</h3>
      <p className="text-faded mb48 mb20-lg-down mt16">{t('PLAYLISTING.CHOOSE-YOUR-SONGS-DESCRIPTION')}</p>
      <div className="">
        <div className="card mb20">
          <Autocomplete
            key={autoCompleteKey}
            options={searchArtistTracksOptions}
            noOptionsText={<p className="text-white">No options available</p>}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={() => true}
            onBlur={handleResetTracksAutocomplete}
            loading={searchArtistTracksOptionsIsLoading}
            loadingText={<Loading size="small" />}
            onChange={(_, value) => {
              if (!value) return;
              handleUpdateTrack({
                id: value.id,
                name: value.name,
                type: 'single',
                artists: value.artists,
                images: value.album.images,
                release_date: value.album.release_date,
              });
              setTrackSearchValue('');
            }}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                <div>
                  <img className="search-list-image br8" src={option.album?.images[0]?.url} alt="" />
                </div>
                <div className="d-flex">
                  <div className="pl16 text-white">
                    <p>{option.name}</p>
                    <p className="small text-faded mt-8">{option.artists[0].name}</p>
                    <p className="small text-faded mt-8">{option.album?.name}</p>
                  </div>
                </div>
              </li>
            )}
            renderInput={(params) => (
              <AutoCompleteInput
                params={params}
                placeholder={t('PLAYLISTING.SEARCH-FOR-TRACKS')}
                onChange={(event) => setTrackSearchValue(event.target.value)}
              />
            )}
          />
          {chosenTracks.length > 0 && (
            <div className="d-flex gap8 flex-item-wrap mt20">
              {chosenTracks?.map((value) => (
                <Chip
                  key={value.id}
                  label={`${value.name} - ${value.type}`}
                  deleteIcon={<Icon>cancel</Icon>}
                  onDelete={() => handleDeleteTrack(value.id)}
                />
              ))}
            </div>
          )}
        </div>

        <div className="card mb20 d-flex gap20 flex-wrap">
          {artistAlbums && artistAlbums?.length > 0 && (
            <div className="w100p text-left hide-lg-up">
              <h4>Albums</h4>
              {artistAlbumsIsLoading ? (
                <div className="mt70">
                  <Loading size="small" />
                </div>
              ) : (
                <div className="d-flex gap20 mt20 jc-space-between overflow-scroll pb10">
                  {artistAlbums?.map((album, index) => (
                    <div
                      key={album.id}
                      data-testid={`playlisting-album-item-${index}`}
                      className="playlisting-album-item flex-grow list-item-parent cursor-pointer"
                      style={
                        {
                          '--animation-number': `${index}`,
                        } as React.CSSProperties
                      }
                      onClick={() => {
                        setActiveAlbumId(album.id);
                        setShowAlbumModal(true);
                      }}
                    >
                      <img src={album.images[0].url} alt="" />
                      <p className="text-center">{album.name}</p>
                      <p className="text-center small text-faded">{format(new Date(album.release_date), 'yyyy')}</p>
                    </div>
                  ))}
                </div>
              )}
              <div className="d-flex form-divider mt10 mb20">
                <div className="line"></div>
              </div>
            </div>
          )}
          <div className="flex-w50p-21 w100p-lg-down text-left">
            <h4>Singles</h4>
            {artistSinglesIsLoading ? (
              <div className="mt70 mb48">
                <Loading size="small" />
              </div>
            ) : (
              artistSingles?.map((single, index) => (
                <div key={single.id}>
                  {single.tracks.map((track, trackIndex) => (
                    <ButtonComponent
                      isCustom={true}
                      key={track.id}
                      dataTestId={`playlisting-single-track-item-${index}-${trackIndex}`}
                      style={
                        {
                          '--animation-number': `${index}`,
                        } as React.CSSProperties
                      }
                      className={`mt20 w100p ${
                        chosenTracks.map((item) => item.id).includes(track.id)
                          ? 'to-do-card-border'
                          : 'to-do-card-pre-border'
                      }`}
                      onClick={() =>
                        handleUpdateTrack({
                          id: track.id,
                          name: track.name,
                          type: 'single',
                          artists: track.artists,
                          images: single.images,
                          release_date: single.release_date,
                        })
                      }
                    >
                      <div className={`list-item list-item-parent campaign card-inner d-flex w100p p12`}>
                        <img src={single.images[0].url} alt="" />
                        <div className="mt-auto mb-auto text-left">
                          <p>{track.name}</p>
                          <p className="text-faded small">
                            {track.artists.map(
                              (item, index) => `${item.name}${index < track.artists.length - 1 ? ', ' : ''}`
                            )}
                          </p>
                          <p className="text-faded small">
                            Released on: {format(new Date(single.release_date), 'yyyy-MM-dd')}
                          </p>
                        </div>
                        <div className="ml-auto mt-auto mb-auto">
                          {chosenTracks.map((item) => item.id).includes(track.id) ? (
                            <div>
                              <Icon className="text-blue">check_circle</Icon>
                            </div>
                          ) : (
                            <div>
                              <Icon className="material-symbols-outlined text-faded">circle</Icon>
                            </div>
                          )}
                        </div>
                      </div>
                    </ButtonComponent>
                  ))}
                </div>
              ))
            )}
          </div>
          <div className="vertical-divider"></div>
          <div className="flex-w50p-21 text-left hide-lg-down">
            <h4>Albums</h4>
            {artistAlbumsIsLoading ? (
              <div className="mt70 mb48">
                <Loading size="small" />
              </div>
            ) : (
              <div className="d-flex flex-item-wrap gap20 mt20 jc-center">
                {artistAlbums?.map((album, index) => (
                  <div
                    key={index}
                    className="playlisting-album-item flex-grow list-item-parent cursor-pointer"
                    style={
                      {
                        '--animation-number': `${index}`,
                      } as React.CSSProperties
                    }
                    onClick={() => {
                      setActiveAlbumId(album.id);
                      setShowAlbumModal(true);
                    }}
                  >
                    <img src={album.images[0].url} alt="" />
                    <p className="text-center">{album.name}</p>
                    <p className="text-center small text-faded">{format(new Date(album.release_date), 'yyyy')}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PitchChooseSongs;
